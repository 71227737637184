import axios from "axios";
import {
  SpotifyArtist,
  SpotifyTopArtistsResult,
  SpotifyTrack,
  TrackArtistInfo,
} from "./types";
import sampleSize from "lodash.samplesize";

const SPOTIFY_BASE = "https://api.spotify.com/v1/" as const;

// ENV vars
export const CLIENT_ID = process.env.REACT_APP_SPOTIFY_CLIENT_ID;
export const REDIRECT_URI = process.env.REACT_APP_SPOTIFY_REDIRECT_URI;
export const AUTH_ENDPOINT = process.env.REACT_APP_SPOTIFY_AUTH_ENDPOINT;
export const RESPONSE_TYPE = process.env.REACT_APP_SPOTIFY_RESPONSE_TYPE;
export const SCOPES = process.env.REACT_APP_SPOTIFY_USER_SCOPES;
export const MUSIC_PLAY_TIME = process.env.REACT_APP_PLAY_TIME;
export const NUMBER_OF_GAME_SONGS = process.env.REACT_APP_NUMBER_OF_GAME_SONGS;

axios.defaults.baseURL = SPOTIFY_BASE;
axios.defaults.headers.common["Content-Type"] = "application/json";

// NOTE: Catch the Retry-After header and add backoff to use this effectively
// axios.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   (error) => {
//     if (
//       error.response.status === 429 &&
//       error.config.url.includes(`/related-artists`)
//     ) {
//       return Promise.resolve([]);
//     }
//     return Promise.reject(error);
//   }
// );

export const getSpotifyAuthURL = () => {
  return `${AUTH_ENDPOINT}?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=${RESPONSE_TYPE}&scope=${SCOPES}`;
};

export const getAccessToken = (hash: string) => {
  return hash
    .substring(1)
    .split("&")
    .find((elem) => elem.startsWith("access_token"))
    ?.split("=")[1];
};

export const findMoreThanOnce = (
  currentList: SpotifyArtist[],
  currentArtist: SpotifyArtist
) => {
  return (
    currentList.filter((artist) => artist.id === currentArtist.id).length >= 1
  );
};

export const removeDuplicateArtists = (result: SpotifyTopArtistsResult[]) => {
  const collectionArtists: SpotifyArtist[] = result.reduce(
    (acc, res) => [...acc, ...res.items],
    [] as SpotifyArtist[]
  );

  return collectionArtists.reduce((accArtist, currentArtist) => {
    if (findMoreThanOnce(accArtist, currentArtist)) {
      return accArtist;
    }
    return [...accArtist, currentArtist];
  }, [] as SpotifyArtist[]);
};

const generateRandomNumber = (max: number, min = 0) => {
  return Math.floor(Math.random() * (max - min)) + min;
};

export const generateRandomTrackList = (
  topTracks: SpotifyTrack[][],
  numberOfSongs: number
): SpotifyTrack[] => {
  const tracks = topTracks.reduce((l, topTrackList) => {
    const tracksWithPreviewUrls = topTrackList.filter(
      (trackInfo) => trackInfo?.preview_url?.length > 0
    );
    const randomTrack = generateRandomNumber(tracksWithPreviewUrls.length);
    return [...l, tracksWithPreviewUrls[randomTrack]];
  }, [] as SpotifyTrack[]);

  return sampleSize(tracks, numberOfSongs);
};

export const mapArtistAndTrackList = (
  artistIdMap: Record<string, SpotifyArtist>,
  trackList: SpotifyTrack[]
) => {
  return trackList.reduce((newList, trackInfo) => {
    return [
      ...newList,
      {
        track: trackInfo,
        artist: artistIdMap[trackInfo?.artistId],
        artistID: trackInfo.artistId,
        artistName: artistIdMap[trackInfo?.artistId].name,
      },
    ];
  }, [] as TrackArtistInfo[]);
};

export const SPOTIFY_API_ENDPOINTS = {
  ME: {
    ME: "me",
    TOP_ARTISTS: "me/top/artists",
  },
  ARTISTS: {
    TOP_SONGS: (id: string) => `artists/${id}/top-tracks?market=US`,
    RELATED_ARTISTS: (id: string) => `artists/${id}/related-artists`,
  },
  PLAYLISTS: {
    CREATE_PLAYLIST: (userId: string) => `users/${userId}/playlists`,
    ADD_SONGS_TO_PLAYLIST: (playlistId: string) =>
      `playlists/${playlistId}/tracks`,
  },
};
