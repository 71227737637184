import eminem from "./album-arts/eminem.jpg";
import pinkFloyd from "./album-arts/pink_floyd.png";
import sprinter from "./album-arts/sprinter.png";
import drake from "./album-arts/drake.png";
import ts from "./album-arts/ts.png";
import beatles from "./album-arts/beatles.jpeg";
import dl from "./album-arts/dl.jpeg";

const IMAGES = [eminem, pinkFloyd, sprinter, drake, ts, beatles, dl] as const;

export { IMAGES };
