import { Toaster } from "react-hot-toast";
import Hero from "./Hero";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getAccessToken, getSpotifyAuthURL } from "./Spotify";
import { removeTokenFromLocalStorage } from "./utils";
import NotFound from "./NotFound";
import Landing from "./Landing";

const App = () => {
  const loginURL = getSpotifyAuthURL();
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    const spotifyRedirectHash = window.location.hash;
    const lsToken = localStorage.getItem("token");

    if (!lsToken && spotifyRedirectHash) {
      const tokenFromHash = getAccessToken(spotifyRedirectHash);
      window.location.hash = "";
      localStorage.setItem("token", tokenFromHash ?? "");
      setToken(tokenFromHash ?? "");
    }

    if (lsToken) {
      setToken(lsToken);
    }
  }, []);

  const logout = useCallback(() => {
    if (token) {
      setToken(null);
      removeTokenFromLocalStorage();
    }
  }, [token]);

  const homeRouteContent = useMemo(() => {
    if (token && token.length) {
      return <Landing logout={logout} token={token} />;
    }
    return <Hero loginURL={loginURL} />;
  }, [token, loginURL, logout]);

  return (
    <>
      <Toaster position="top-center" gutter={8} reverseOrder />
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/" element={homeRouteContent} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
