import { classNames } from "./utils";

interface ButtonProps
  extends Exclude<
    React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >,
    "className"
  > {
  //TODO: provide proper tailwind classname
  width?: string;
  textsize?: "sm" | "md" | "lg" | `${number}xl`;
  styles?: string[];
  bgcolor?: string;
}

const Button = (props: ButtonProps) => {
  return (
    <button
      className={classNames(
        "block rounded-md px-3 py-2 text-center font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 items-center justify-center",
        props.width ?? "w-full",
        props.textsize ?? "text-sm",
        !props.disabled
          ? !props.bgcolor?.length
            ? "bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600"
            : props.bgcolor
          : "bg-gray-400 hover:bg-gray-500",
        props.styles?.length ? props.styles.join(" ") : ""
      )}
      {...props}
    >
      {props.children}
    </button>
  );
};

export default Button;
