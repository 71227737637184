import { useState, useRef } from "react";
import {
  NUMBER_OF_GAME_SONGS,
  SPOTIFY_API_ENDPOINTS,
  findMoreThanOnce,
  generateRandomTrackList,
  mapArtistAndTrackList,
  removeDuplicateArtists,
} from "./Spotify";
import axios from "axios";
import {
  SpotifyArtist,
  SpotifyTopArtistsResult,
  SpotifyTopTracksOfArtist,
  SpotifyUserInfo,
  TrackArtistInfo,
} from "./types";
import toast from "react-hot-toast";
import Game from "./Game";
import Button from "./Button";
import Loading from "./Loading";
import sampleSize from "lodash.samplesize";

type GameHomeProps = {
  token: string | null;
  userInfo: SpotifyUserInfo;
};

const GameHome = ({ token, userInfo }: GameHomeProps) => {
  const playerTopArtists = useRef<SpotifyArtist[]>([]);
  const topArtistIDMap = useRef<Record<string, SpotifyArtist>>({});
  const [artistTrackInfoList, updateArtistTrackInfoList] = useState<
    TrackArtistInfo[]
  >([]);
  const [onClickBoogie, updateOnClickBoogieState] = useState(false);

  const getReadyToPlay = async () => {
    // NOTE: add some caching - or save the data - instead of refreshing every time (saves API calls)
    updateOnClickBoogieState(true);
    try {
      const topArtistsResult = await Promise.all(
        ["short_term", "medium_term", "long_term"].map((timeLen) =>
          axios.get<SpotifyTopArtistsResult>(
            `${SPOTIFY_API_ENDPOINTS.ME.TOP_ARTISTS}?time_range=${timeLen}&limit=50`,
            // NOTE: keeping this line for future sake :P
            // `${SPOTIFY_API_ENDPOINTS.ME.TOP_ARTISTS}?time_range=${timeLen}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
        )
      );

      if (topArtistsResult.find((res) => res.status !== 200)) {
        toast.error("Unknown error has occurred");
        console.error(
          "Unknown error while fetching top artist information",
          topArtistsResult
        );
        return;
      }

      const topArtistsResultData = removeDuplicateArtists(
        topArtistsResult.map((res) => res.data)
      );

      const topRelatedArtistsResult = await Promise.all(
        sampleSize(topArtistsResultData, 6).map((topArtist) =>
          axios.get<{ artists: SpotifyArtist[] }>(
            `${SPOTIFY_API_ENDPOINTS.ARTISTS.RELATED_ARTISTS(topArtist.id)}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
        )
      );

      if (topRelatedArtistsResult.find((res) => res.status !== 200)) {
        // toast.error("Unknown error has occurred");
        console.error(
          "Unknown error while fetching related artist information",
          topRelatedArtistsResult
        );
      } else {
        topRelatedArtistsResult.forEach((trar) => {
          topArtistsResultData.push(...sampleSize(trar.data.artists, 4));
        });
      }

      playerTopArtists.current = topArtistsResultData.reduce(
        (accArtist, currentArtist) => {
          if (findMoreThanOnce(accArtist, currentArtist)) {
            return accArtist;
          }
          return [...accArtist, currentArtist];
        },
        [] as SpotifyArtist[]
      );

      const topArtistIDMapData = topArtistsResultData.reduce(
        (artistIDMap, artistInfo) => {
          return { ...artistIDMap, [artistInfo.id]: artistInfo };
        },
        {}
      );
      topArtistIDMap.current = topArtistIDMapData;

      const topTracksResult = await Promise.all(
        topArtistsResultData.map((artist) =>
          axios.get<SpotifyTopTracksOfArtist>(
            SPOTIFY_API_ENDPOINTS.ARTISTS.TOP_SONGS(artist.id),
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
        )
      );

      if (topTracksResult.find((topTracks) => topTracks.status !== 200)) {
        toast.error(
          "Unknown error has occurred while fetching tracks for the game."
        );
        console.error(
          "Unknown error while fetching top artist information",
          topTracksResult
        );
        return;
      }

      const artistIdsForTracks = topTracksResult.map(
        (topTracks) => topTracks.config.url?.split("/")[1] ?? ""
      );

      const topTracks = generateRandomTrackList(
        topTracksResult.map((topTracks, i) =>
          topTracks.data.tracks.map((track) => ({
            ...track,
            artistId: artistIdsForTracks[i],
          }))
        ),
        parseInt(NUMBER_OF_GAME_SONGS ?? "5")
      );
      updateArtistTrackInfoList(
        mapArtistAndTrackList(topArtistIDMap.current, topTracks)
      );
    } catch (e) {
      toast.error("Failed to fetch your top artists to set up the game!");
      console.error("Failed to fetch top artists: ", e);
    }
    updateOnClickBoogieState(false);
  };

  return (
    <div className="flex flex-col justify-center items-center">
      {token && (
        <>
          {!onClickBoogie && !artistTrackInfoList.length ? (
            <Button
              onClick={getReadyToPlay}
              width="w-[90%] mx-5 md:w-[60%]"
              styles={["max-h-[120px]"]}
            >
              <span className="md:text-3xl text-2xl">Let's Boogie!</span>
            </Button>
          ) : artistTrackInfoList.length ? (
            <Game
              userInfo={userInfo}
              trackList={artistTrackInfoList}
              token={token}
            />
          ) : (
            <Loading />
          )}
        </>
      )}
    </div>
  );
};

export default GameHome;
