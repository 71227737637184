import { Disclosure } from "@headlessui/react";
import {
  ArrowLeftStartOnRectangleIcon,
  MusicalNoteIcon,
} from "@heroicons/react/24/outline";
import GameHome from "./GameHome";
import axios from "axios";
import { SPOTIFY_API_ENDPOINTS } from "./Spotify";
import { SpotifyUserInfo } from "./types";
import { useEffect, useMemo, useState } from "react";
import sampleSize from "lodash.samplesize";

type LandingProps = {
  logout: () => void;
  token: string | null;
};

const DEFAULT_USER_INFO: SpotifyUserInfo = {
  country: "",
  display_name: "",
  email: "",
  href: "",
  id: "",
  images: [],
  product: "",
  type: "",
  uri: "",
};

const Landing = ({ logout, token }: LandingProps) => {
  const [userInfo, updateUserInfo] =
    useState<SpotifyUserInfo>(DEFAULT_USER_INFO);

  const fetchUserInfo = async () => {
    try {
      const userInfoResponse = await axios.get<SpotifyUserInfo>(
        SPOTIFY_API_ENDPOINTS.ME.ME,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (userInfoResponse.status === 200) {
        updateUserInfo(userInfoResponse.data);
      } else {
        console.error(
          "errors occurred while fetching user info: ",
          userInfoResponse.data
        );
      }
    } catch (e) {
      console.error("failed to fetch user info: ", e);
    }
  };

  useEffect(() => {
    fetchUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const greeting = useMemo(() => {
    const greetings = [
      "Rock Star Detective",
      "Melody Master",
      "Beat Boss",
      "Lyric Legend",
      "Melody Maverick",
      "Track Titan",
    ] as const;

    if (userInfo.display_name) {
      return `Are you a ${sampleSize(greetings, 1)[0]}, ${
        userInfo.display_name
      }?`;
    }
  }, [userInfo.display_name]);

  return (
    <>
      <div className="min-h-full">
        <Disclosure as="nav" className="bg-gray-200 sticky top-0 mt-[-2px]">
          {() => (
            <>
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="flex h-16 items-center justify-between">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <MusicalNoteIcon className="h-8 w-8" />
                    </div>
                  </div>
                  <div className="md:block">
                    <div className="ml-4 flex items-center md:ml-6">
                      <button
                        onClick={logout}
                        type="button"
                        title="Logout"
                        className="relative rounded-full bg-gray-200 p-1 text-gray-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                      >
                        <span className="absolute -inset-1.5" />
                        <span className="sr-only">Logout</span>
                        <ArrowLeftStartOnRectangleIcon
                          className="h-6 w-6"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </Disclosure>
        <header className="bg-white shadow">
          <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
            <h1 className="md:text-3xl text-xl font-bold tracking-tight text-gray-900">
              {greeting}
            </h1>
          </div>
        </header>
        <main>
          <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
            <GameHome userInfo={userInfo} token={token} />
          </div>
        </main>
      </div>
    </>
  );
};

export default Landing;
