const TOKEN_KEY = "token" as const;

export const getTokenFromLocalStorage = () => {
  return window.localStorage.getItem(TOKEN_KEY) ?? "";
};

export const setTokenToLocalStorage = (token: string) => {
  window.localStorage.setItem(TOKEN_KEY, token);
};

export const removeTokenFromLocalStorage = () => {
  window.localStorage.removeItem(TOKEN_KEY);
};

export const capitalizeText = (text: string) => {
  return text
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const classNames = (...classes: string[]) => {
  return classes.filter(Boolean).join(" ");
};
