import { useEffect, useRef, useState } from "react";
import Marquee from "react-fast-marquee";
import { classNames } from "./utils";

type PreviewInfo = {
  bgImage: string;
  name: string;
  artist: string;
};

type PlayerProps = {
  audioSrc: string;
  disabled?: boolean;
  titles?: {
    play?: React.ReactNode;
    pause?: React.ReactNode;
  };
  timeToPlay?: number;
  previewInfo?: PreviewInfo;
  stopSong?: boolean;
  styles?: string[];
};

const Player = ({
  audioSrc,
  titles = { play: "Play", pause: "Playing..." },
  timeToPlay,
  disabled = false,
  previewInfo,
  stopSong,
  styles,
}: PlayerProps) => {
  const [playState, updatePlayState] = useState(false);
  const music = useRef(new Audio(audioSrc));
  const timer = useRef<NodeJS.Timer | null>(null);

  useEffect(() => {
    music.current.src = audioSrc;
    if (music.current) {
      music.current.load();
    }
    return () => {
      if (timer.current) {
        clearInterval(timer.current);
      }
      if (music.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        music.current.pause();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (stopSong) {
      if (timer.current) {
        clearInterval(timer.current);
      }
      music.current.pause();
      music.current.load();
    }
  }, [stopSong]);

  useEffect(() => {
    let time = timeToPlay;

    if (timeToPlay && playState) {
      timer.current = setInterval(() => {
        if (time) {
          time--;
        }
        if (time === 0 || !playState) {
          if (timer.current) {
            clearInterval(timer.current);
          }
          music.current.load();
          updatePlayState(false);
        }
      }, 1000);
    } else if (!playState) {
      time = timeToPlay;
      if (timer.current) {
        clearInterval(timer.current);
        music.current.load();
      }
    }
  }, [timeToPlay, playState]);

  useEffect(() => {
    if (playState) {
      music.current.src = audioSrc;
      music.current.load();
      music.current.play();
    } else {
      music.current.pause();
      music.current.load();
    }
  }, [playState, music, audioSrc]);

  return (
    <>
      {!previewInfo ? (
        <div
          className={classNames(
            "flex items-center justify-center",
            ...(styles ?? [])
          )}
        >
          <button
            onClick={() => updatePlayState((prevState) => !prevState)}
            disabled={disabled}
          >
            {playState ? titles.pause : titles.play}
          </button>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center">
          <img src={previewInfo.bgImage} alt="" height={250} width={250} />
          <audio
            src={audioSrc}
            controls
            controlsList="nodownload noplaybackrate"
            className="mt-4 max-w-[250px]"
          />
          <div className="max-w-[250px]">
            <Marquee>
              <span className="mt-4 font-semibold text-lg">
                &nbsp;
                {previewInfo.name} - {previewInfo.artist}
                &nbsp;
              </span>
            </Marquee>
          </div>
        </div>
      )}
    </>
  );
};

export default Player;
