import { useEffect, useMemo, useRef, useState } from "react";
import {
  SpotifyCreatePlaylistResponse,
  SpotifyUserInfo,
  TrackArtistInfo,
} from "./types";
import Player from "./Player";
import stringSimilarity from "string-similarity-js";
import toast from "react-hot-toast";
import "./index.css";
import { capitalizeText, classNames } from "./utils";
import Button from "./Button";
import {
  MusicalNoteIcon,
  PauseIcon,
  PlayIcon,
} from "@heroicons/react/24/outline";
import { MUSIC_PLAY_TIME, SPOTIFY_API_ENDPOINTS } from "./Spotify";
import axios from "axios";
import ConfettiExplosion from "react-confetti-explosion";

type GameProps = {
  readonly trackList: TrackArtistInfo[];
  readonly userInfo: SpotifyUserInfo;
  readonly token: string;
};

type Answer = {
  text: string;
  correct: boolean;
};

type GameState = {
  currentPos: number;
  answers: Answer[];
  correct: number;
};

const DEFAULT_GAME_STATE: GameState = {
  currentPos: 0,
  answers: [],
  correct: 0,
};

const Game = ({ trackList, userInfo, token }: GameProps) => {
  const [answer, updateAnswer] = useState("");
  const [gameState, updateGameState] = useState<GameState>(DEFAULT_GAME_STATE);
  const [showNext, updateShowNextState] = useState(false);
  const shouldStopSong = useRef<boolean>(false);

  const onChangeAnswerText = (e: React.ChangeEvent<HTMLInputElement>) => {
    const currentVal = e.target.value;
    updateAnswer(currentVal);
  };

  const isAnswerCorrect = (songName: string, userAns: string, ans: string) => {
    return (
      stringSimilarity(ans, userAns) >= 0.7 ||
      songName.includes(userAns) ||
      ans.includes(userAns)
    );
  };

  const onClickCheckAnswer = (currentPos: number) => {
    const currentAnswer = trackList[currentPos].artistName.toLowerCase();
    const cleanedUserAnswer = answer.trim().toLowerCase();
    const answerCorrectness = isAnswerCorrect(
      trackList[currentPos].track.name.toLowerCase(),
      cleanedUserAnswer,
      currentAnswer
    );
    shouldStopSong.current = true;

    updateGameState((prevState) => ({
      ...prevState,
      answers: [
        ...prevState.answers,
        {
          text: cleanedUserAnswer,
          correct: answerCorrectness,
        },
      ],
    }));

    if (answerCorrectness) {
      toast.success("Bingo!");
      updateGameState((prevState) => ({
        ...prevState,
        correct: prevState.correct + 1,
      }));
    } else {
      toast.error("The answer is: " + capitalizeText(currentAnswer));
    }

    updateShowNextState(true);
  };

  const onClickNextBtn = () => {
    updateAnswer("");
    updateShowNextState(false);
    updateGameState((prevState) => ({
      ...prevState,
      currentPos: prevState.currentPos + 1,
    }));
    shouldStopSong.current = false;
  };

  const hasGameEnded = useMemo(() => {
    return (
      gameState.currentPos === trackList.length - 1 &&
      gameState.answers.length !== trackList.length - 1
    );
  }, [gameState.currentPos, trackList.length, gameState.answers.length]);

  useEffect(() => {
    if (hasGameEnded) {
      toast.success(
        `Game over! You finished with a score of ${gameState.correct}/${trackList.length} 🎉`
      );
      updateAnswer("");
    }
  }, [hasGameEnded, gameState.correct, trackList.length]);

  const hasValidAnswer = useMemo(() => {
    return answer.trim().length > 0;
  }, [answer]);

  const currentTrack = useMemo(() => {
    const currentTrackPreview =
      trackList[gameState.currentPos].track.preview_url;
    return (
      <div className="mb-12">
        <Player
          audioSrc={currentTrackPreview}
          titles={{
            play: <PlayIcon className="h-24 w-24" />,
            pause: <PauseIcon className="h-24 w-24" />,
          }}
          timeToPlay={parseInt(MUSIC_PLAY_TIME ?? "7")}
          stopSong={shouldStopSong.current}
          styles={["border-slate-400 border-[3px] rounded-xl"]}
        />
      </div>
    );
  }, [gameState.currentPos, trackList]);

  const currentTrackWithPreview = useMemo(() => {
    const currentTrackPreview =
      trackList[gameState.currentPos].track.preview_url;
    const currentTrackImage =
      trackList[gameState.currentPos].track.album.images[0];
    const currentTrackName = trackList[gameState.currentPos].track.name;
    const currentTrackArtist = trackList[gameState.currentPos].artistName;
    return (
      <div className="mb-4">
        <Player
          previewInfo={{
            bgImage: currentTrackImage.url,
            name: currentTrackName,
            artist: currentTrackArtist,
          }}
          audioSrc={currentTrackPreview}
          titles={{
            play: <PlayIcon className="h-24 w-24" />,
            pause: <PauseIcon className="h-24 w-24" />,
          }}
        />
      </div>
    );
  }, [gameState.currentPos, trackList]);

  const onClickSavePlaylist = async () => {
    try {
      const createPlaylistResponse =
        await axios.post<SpotifyCreatePlaylistResponse>(
          SPOTIFY_API_ENDPOINTS.PLAYLISTS.CREATE_PLAYLIST(userInfo.id),
          {
            name: `niddy-gritty playlist-${new Date().toLocaleDateString()}`,
            description: "played a game on https://niddy-gritty.vercel.app/",
            public: false,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

      if (createPlaylistResponse.status !== 201) {
        toast.error("failed to save playlist");
        console.error(
          createPlaylistResponse.data,
          createPlaylistResponse.status
        );
        return;
      }

      const newPlaylistInfo = createPlaylistResponse.data;

      const updatePlaylistWithSongsResponse = await axios.post(
        SPOTIFY_API_ENDPOINTS.PLAYLISTS.ADD_SONGS_TO_PLAYLIST(
          newPlaylistInfo.id
        ),
        {
          uris: trackList.map((track) => track.track.uri),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (updatePlaylistWithSongsResponse.status !== 201) {
        toast.error(
          `failed to add songs to the playlist: ${newPlaylistInfo.name}`
        );
        console.error(
          updatePlaylistWithSongsResponse.data,
          updatePlaylistWithSongsResponse.status
        );
      } else {
        toast.success(`Created a new playlist: ${newPlaylistInfo.name}`);
      }
    } catch (e) {
      console.error("failed to save playlist", e);
      toast.error("failed to save error due to unknown error");
    }
  };

  return (
    <>
      {(showNext && gameState.answers[gameState.currentPos].correct) ||
      hasGameEnded ? (
        <ConfettiExplosion particleCount={250} force={0.6} duration={2500} />
      ) : null}
      <div className="h-full w-full flex flex-col items-center justify-center">
        <span className="text-[30px] font-semibold">
          {!hasGameEnded ? `Track ${gameState.currentPos + 1} - ` : null}
          {hasGameEnded ? "Final" : ""} Score: {gameState.correct}/
          {trackList.length}
        </span>
        {hasGameEnded ? (
          <>
            <div className="flex justify-between my-4 md:w-[50%] w-[90%]">
              <Button onClick={() => window.location.reload()}>
                Play Again?
              </Button>
              <Button
                onClick={onClickSavePlaylist}
                bgcolor="bg-black"
                styles={["ml-8"]}
              >
                <span className="flex flex-row items-center justify-center">
                  <MusicalNoteIcon className="color-white h-4 w-4 mr-2" />
                  <p>Save this playlist!</p>
                </span>
              </Button>
            </div>
            <ul className="divide-y divide-gray-100 md:max-w-[50%] w-[90%]">
              {trackList.map((track, ti) => (
                <li
                  key={track.track.id}
                  className={classNames(
                    "flex justify-between gap-x-6 py-5 px-3",
                    gameState.answers[ti].correct
                      ? "bg-green-100"
                      : "bg-red-100"
                  )}
                >
                  <div className="flex min-w-0 gap-x-4">
                    <img
                      className="h-12 w-12 flex-none rounded-full bg-gray-50"
                      src={track.track.album.images[0].url}
                      alt=""
                    />
                    <div className="min-w-0 flex-auto">
                      <p className="text-sm font-semibold leading-6 text-gray-900">
                        {track.track.name}
                      </p>
                      <p className="mt-1 truncate text-sm leading-5 text-gray-500">
                        {track.artistName}
                      </p>
                    </div>
                  </div>
                  <div className="shrink-0 sm:flex sm:flex-col sm:items-end">
                    <Player
                      audioSrc={track.track.preview_url}
                      titles={{
                        play: <PlayIcon className="h-8 w-8" />,
                        pause: <PauseIcon className="h-8 w-8" />,
                      }}
                    />
                  </div>
                </li>
              ))}
            </ul>
          </>
        ) : null}
        <div className="mt-8 flex flex-col h-full w-full items-center justify-center">
          {showNext && gameState.currentPos !== trackList.length - 1 ? (
            <>
              {currentTrackWithPreview}
              <form
                onSubmit={onClickNextBtn}
                className="w-full flex flex-col items-center justify-center"
              >
                <Button
                  type="submit"
                  width="w-[50%] max-w-[250px]"
                  textsize="lg"
                >
                  Next
                </Button>
              </form>
            </>
          ) : (
            <div className="flex flex-col items-center justify-center w-full h-full">
              {!hasGameEnded ? (
                <>
                  {currentTrack}
                  <form
                    className="w-[90%] md:w-[40%]"
                    onSubmit={() => onClickCheckAnswer(gameState.currentPos)}
                  >
                    <div>
                      <label
                        htmlFor="guess"
                        className="block text-lg font-medium leading-6 text-gray-900"
                      >
                        Who's the artist?
                      </label>
                      <div className="relative mt-2 rounded-md shadow-sm">
                        <input
                          type="text"
                          name="guess"
                          id="guess"
                          className="block w-full rounded-md border-0 py-1.5 pl-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          value={answer}
                          onChange={onChangeAnswerText}
                          placeholder="Go on...write down your best guess!"
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <Button
                      disabled={!hasValidAnswer}
                      styles={[
                        !hasValidAnswer
                          ? "cursor-not-allowed"
                          : "cursor-pointer",
                        "mt-4",
                      ]}
                      type="submit"
                    >
                      Check Answer
                    </Button>
                  </form>
                </>
              ) : null}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Game;
